/* 此樣式為直接覆蓋套件的日曆樣式 */

.fc {
    flex: 1;
}

.fc-header-toolbar .fc-toolbar {
    margin-bottom: 15px !important;
}

.fc .fc-buttom { 
    padding: 0 0.65em !important;
}

.fc-toolbar-chunk {
    display: flex;
}

.fc button.fc-button-primary {
    border-color: #E9E9E9;
    background-color: #E9E9E9;
    color: #212121;
    padding: 0 0.65em;
}

.fc button.fc-button-primary[title="Add New Meeting +"] {
    border-color: #7EB442;
    background-color: #7EB442;
    color: #ffffff;
}